<template>
  <div>
    <v-container class="background dark" v-if="!isMobile">
        <v-row class="text-center" justify="center">
            <v-col cols="12">
                <p class="title">Increase Community<br>
                Engagement!</p>
            </v-col>
            <v-col cols="12" class="lineAlignment">
                <p class="subtitle">Provide your community with a new way to have fun together.</p>
            </v-col>
            <v-col cols="12">
                <v-btn depressed dark class="interaction community button" style="margin-top: 0.75vh; margin-bottom: 6vh;" @click="addDiscord">Add to Server</v-btn>
            </v-col>
        </v-row>
    </v-container>

    <v-container class="background dark" v-if="isMobile">
        <v-row class="text-center" justify="center">
            <v-col cols="12">
                <p class="mobileTitle">Increase Community<br>
                Engagement!</p>
            </v-col>
            <v-col cols="12" class="lineAlignment">
                <p class="subtitle">Provide your community with a<br>
                new way to have fun together.</p>
            </v-col>
            <v-col cols="12">
                <v-btn depressed dark class="interaction community button mobile" @click="addDiscord">Add to Server</v-btn>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Content",

  data: () => ({
      isMobile: false,
  }),

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 801;
    },

    addDiscord()
    {
        window.open("https://discord.com/login?redirect_to=%2Foauth2%2Fauthorize%3Fclient_id%3D1076499064390156348%26permissions%3D52288%26scope%3Dapplications.commands%2520bot", '_blank').focus();
    }
  }
};
</script>

<style scoped>
.lineAlignment
{
    padding: 0;
}

.interaction.community.button
{
    padding: 20px !important;
}

.community.button.mobile
{
    margin-top: 1vh;
    margin-bottom: 10vh;
}

.title
{
    color: #ffffff; 
    font-size: 3.8rem !important;
    line-height: 4.5rem;
    font-weight: 600;
    margin-top: 8vh;
    font-weight: 600;
}

.mobileTitle
{
    color: #ffffff; 
    font-size: 3rem !important;
    line-height: 3.5rem;
    font-weight: 600;
    margin-top: 8vh;
    font-weight: 600;
}

.subtitle
{
    font-size: 1.4rem;
    font-weight: 400;
    color: #d0ccd4;
    font-weight: 500;
}
</style>