<template>
    <div>
        <v-container class="background dark" v-if="!isMobile">
            <span class="ponderingSpacing">
                <v-img
                    alt="Guess the Word Logo"
                    :src="require('../assets/Pondering.png')"
                    class="pondering"
                    position="left"
                    height="28vh"
                    contain>
                </v-img>

                <p class="secondaryTitle">18 Themes</p>

                <p class="secondarySubtitle">Enjoy the most popular<br>
                themes from Scribble It! in<br>
                this chat-based adaption.</p>

                <v-img
                    alt="Ranking Table"
                    :src="require('../assets/RankingTable.png')"
                    class="rankingImageSpacing"
                    position="left"
                    height="35vh"
                    width="35vh">
                </v-img>
            </span>
            <span>
                <p class="mainTitle">Guess Drawings &<br>
                Win Trophies</p>

                <p class="mainSubtitle">Guess the correct word first and beat your<br>
                friends</p>

                <v-btn depressed dark class="interaction content button" @click="addDiscord">Add to Server</v-btn>

                <v-img
                    alt="Guess the Word Logo"
                    :src="require('../assets/WordPackages.png')"
                    class="themesImageSpacing"
                    contain
                    position="left"
                    height="36vh">
                </v-img>

                <p class="recordTitle">Break Records</p>

                <p class="recordSubtitle">Beat endless levels and climb the all<br>
                time server scoreboard</p>
            </span>
        </v-container>

        <v-container class="background dark" v-if="isMobile">
            <span>
                <p class="mobileTitle">Guess Drawings &<br>
                Win Trophies</p>

                <p class="mobileSubtitle">Guess the correct word first<br>
                and beat your friends</p>

                <v-btn depressed dark class="interaction content button mobile" @click="addDiscord">Add to Server</v-btn>

                <p class="mobileTitle">22 Themes</p>

                <p class="mobileSubtitle">Enjoy the most popular themes<br>
                from ScribbleIt! in this chat-<br>
                base adaption.</p>

                <v-img
                    alt="Guess the Word Logo"
                    :src="require('../assets/WordPackages.png')"
                    class="mobileThemesImageSpacing"
                    contain
                    position="left"
                    width="87.5vw">
                </v-img>

                <p class="mobileTitle">Break Records</p>

                <p class="mobileSubtitle">Beat endless levels and climb<br>
                the all time server scoreboard</p>

                <v-img
                    alt="Ranking Table"
                    :src="require('../assets/RankingTable.png')"
                    class="mobileRankingImageSpacing"
                    position="left"
                    width="87.5vw">
                </v-img>
            </span>
        </v-container>
    </div>
</template>

<script>
export default {
  name: "Content",

  data: () => ({
      isMobile: false,
  }),

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() 
    {
      this.isMobile = window.innerWidth < 801;
    },

    addDiscord()
    {
        window.open("https://discord.com/login?redirect_to=%2Foauth2%2Fauthorize%3Fclient_id%3D1076499064390156348%26permissions%3D52288%26scope%3Dapplications.commands%2520bot", '_blank').focus();
    }
  }
};
</script>

<style scoped>
.interaction.content.button
{
    padding: 20px !important;
}

.content.button.mobile
{
    margin-left: 4vw;
}

.ponderingSpacing
{
    margin-top: 17vh;
    margin-left: 28vw;
}

.themesImageSpacing
{
    margin-top: 16vh;
}

.mobileThemesImageSpacing
{
    margin-left: 4vw;
    margin-top: 6vh;
}

.rankingImageSpacing
{
    margin-top: 30vh;
}

.mobileRankingImageSpacing
{
    margin-left: 2.5vw;
}

.pondering
{
    margin-top: 4vh;
}

.mainTitle
{
   color: #ffffff; 
   font-size: 3.8rem;
   line-height: 4.5rem;
   font-weight: 600;
   margin-top: 18vh;
}

.mobileTitle
{
    color: #ffffff; 
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 600;
    margin-top: 10vh;
    margin-left: 4vw;
}

.mainSubtitle
{
    padding-top: 1vh;
    color: #d0ccd4;
    font-size: 1.5rem;
}

.mobileSubtitle
{
    color: #d0ccd4;
    font-size: 1.5rem;
    margin-left: 4vw;
}

.secondaryTitle
{
    padding-top: 29vh;
    font-size: 2.9rem;
    font-weight: 600;
    color: #ffffff; 
}

.secondarySubtitle
{
    font-size: 1.4rem;
    font-weight: 400;
    color: #d0ccd4;
}

.recordTitle
{
    padding-top: 30vh;
    font-size: 2.9rem;
    font-weight: 600;
    color: #ffffff; 
    margin-left: 1vw;
    margin-top: 2vh;
}

.recordSubtitle
{
    font-size: 1.4rem;
    font-weight: 400;
    color: #d0ccd4;
    margin-left: 1vw;
}
</style>