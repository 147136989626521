<template>
    <div class="background dark footer">
        <span>
            <p class="resources">Resources</p>
            <v-btn color="#d0ccd4" plain class="linkStyle" @click="$router.push({ name: 'Privacy Policy' })">Privacy</v-btn><br>
            <v-btn color="#d0ccd4" plain class="linkStyle" @click="$router.push({ name: 'Terms of Service' })">Terms of Service</v-btn><br>
            <v-btn color="#d0ccd4" plain class="linkStyle" href="mailto:privacy@detach-entertainment.com">Contact</v-btn><br>
            <p class="rights">© Detach Entertainment UG (haftungsbeschränkt). All Rights Reserved.</p>
        </span>
    </div>
</template>

<script>
export default {
  name: "Content"
};
</script>

<style scoped>
@media only screen and (max-width: 800px) {
    .background.footer
    {
        padding: 10vw;
    }
}

@media only screen and (min-width: 801px) {
    .background.footer
    {
        padding: 3vw;
    }
}

.background.footer
{
    border-top: solid 2px #595959;
}

.resources
{
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 1vh;
}

.linkStyle
{
    color: #d0ccd4 !important;
    font-weight: 500;
}

.rights
{
    color: #848889 !important;
    font-weight: 500;
    margin-top: 4vh;
    margin-bottom: 0;
}
</style>