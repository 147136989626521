import Vue from 'vue';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify';
import goTo from 'vuetify/es5/services/goto';

import App from './App.vue'
import LandingPage from "./pages/LandingPage.vue";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
    routes: [
        {
            path: '/',
            name: 'Home',
            component: LandingPage
        },
        {
            path: '/privacy-policy',
            name: 'Privacy Policy',
            component: () =>
                import ('./pages/PrivacyPolicy.vue')
        },
        {
            path: '/terms-of-service',
            name: 'Terms of Service',
            component: () =>
                import ('./pages/TermsOfService.vue')
        },
        // catch all redirect
        { path: '*', redirect: '/' }
    ]
})

Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')