<template>
    <div>
        <Header />

        <Landing />
        <Content />
        <Commands />
        <Statistics />
        <Community />

        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header.vue';
import Landing from '../components/Landing.vue';
import Content from '../components/Content.vue';
import Commands from '../components/Commands.vue';
import Statistics from '../components/Statistics.vue';
import Community from '../components/Community.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'LandingPage',

  components: {
    Header,
    Landing,
    Content,
    Commands,
    Statistics,
    Community,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>
