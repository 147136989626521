<template>
    <div>
        <v-container class="background dark" v-if="!isMobile">
            <span class="spacingDesktop">
                <p class="title" id="how-to-play">Easy Setup</p>

                <p class="content">1. Add the bot to your Server<br>
                2. Type <a class="boldText">/gtw start</a></p>

                <p class="boldText textSpacing">The bot needs minimum permissions and will never ready any data from<br>
                your server</p>

                <table class="commandsTable">
                    <tr class="tableHead">
                        <th>
                            Command
                        </th>
                        <th>
                            Use
                        </th>
                    </tr>
                    <tr class="tableContent">
                        <td>/gtw start</td>
                        <td>Starts a new game of Guess the Word.</td>
                    </tr>
                    <tr class="tableContent">
                        <td>/gtw hint</td>
                        <td>Reveals a hint only visible for the performing player.</td>
                    </tr>
                    <tr class="tableContent">
                        <td>/gtw leaderboard</td>
                        <td>Shows the all time server-wide leaderboard.</td>
                    </tr>
                    <tr class="tableContent">
                        <td>/gtw rank</td>
                        <td>Shows the rank of the performing player.</td>
                    </tr>
                    <tr class="tableContent">
                        <td>/gtw-admin cancel</td>
                        <td>(ADMIN ONLY) Cancels the current game.</td>
                    </tr>
                </table>
            </span>
        </v-container>

        <v-container class="background dark" v-if="isMobile">
            <span class="spacingMobile">
                <p class="title">Easy Setup</p>

                <p class="content">1. Add the bot to your Server<br>
                2. Type <a class="boldText">/gtw start</a></p>

                <p class="boldText textSpacing">The bot needs minimum<br>
                permissions and will never ready<br>
                any data from your server</p>

                <table class="commandsTableMobile">
                    <tr class="tableHead">
                        <th class="mobileTableSpacing">
                            Command
                        </th>
                        <th class="mobileTableSpacing">
                            Use
                        </th>
                    </tr>
                    <tr class="tableContentMobile">
                        <td class="mobileTableSpacing">/gtw start</td>
                        <td class="mobileTableSpacing">Starts a new game of Guess the Word.</td>
                    </tr>
                    <tr class="tableContentMobile">
                        <td class="mobileTableSpacing">/gtw hint</td>
                        <td class="mobileTableSpacing">Reveals a hint only visible for the performing player.</td>
                    </tr>
                    <tr class="tableContentMobile">
                        <td class="mobileTableSpacing">/gtw leaderboard</td>
                        <td class="mobileTableSpacing">Shows the all time server-wide leaderboard.</td>
                    </tr>
                    <tr class="tableContentMobile">
                        <td class="mobileTableSpacing">/gtw rank</td>
                        <td class="mobileTableSpacing">Shows the rank of the performing player.</td>
                    </tr>
                    <tr class="tableContentMobile">
                        <td class="mobileTableSpacing">/gtw-admin cancel</td>
                        <td class="mobileTableSpacing">(ADMIN ONLY) Cancels the current game.</td>
                    </tr>
                </table>
            </span>
        </v-container>
    </div>
</template>

<script>
export default {
  name: "Content",

  data: () => ({
      isMobile: false,
  }),

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 801;
    },
  }
};
</script>

<style scoped>
span
{
    display: block;
}

.spacingDesktop
{
    margin-left: 28vw !important;
}

.spacingMobile
{
    margin-left: 2vw !important;
}

.title
{
    font-size: 2.9rem !important;
    font-weight: 600;
    color: #ffffff; 
    margin-top: 10vh;
    margin-bottom: 4vh;
}

.content
{
    font-size: 1.4rem !important;
    color: #d0ccd4;
    font-weight: 500;
}

.boldText
{
    font-size: 1.4rem !important;
    font-weight: 600;
    color: #d0ccd4;
    font-weight: 600;
}

.textSpacing
{
    margin-top: 4vh;
    margin-bottom: 4vh;
}

.commandsTable
{
    text-align: left;
    width: 40vw;
    border-collapse: collapse;
    font-weight: 500;
    margin-bottom: 8vh;
}

.commandsTableMobile
{
    text-align: left;
    width: 90vw;
    border-collapse: collapse;
    font-weight: 500;
}

td, th
{
    padding-left: 1vw;
    padding-right: 1vw;
}

.mobileTableSpacing
{
    padding-left: 4vw;
    padding-right: 4vw;
}

.tableHead
{
    height: 7vh;
    background-color: #404040;
    color: #ffffff;
}

.tableContent
{
    height: 7vh;
    background-color: #252525;
    color: #ffffff;
}

.tableContentMobile
{
    height: 13vh;
    background-color: #252525;
    color: #ffffff;
}
</style>