<template>
    <div>
        <v-container class="background light" v-if="!isMobile">
            <v-row class="statistics text-center" justify="center">
                <v-col cols="4">
                    <p class="title">6000+</p>
                    <p class="subtitle">Words</p>
                </v-col>
                <v-col cols="4">
                    <p class="title">22</p>
                    <p class="subtitle">Word Themes</p>
                </v-col>
                <v-col cols="4">
                    <p class="title">17</p>
                    <p class="subtitle">Languages</p>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="background light text-center" v-if="isMobile">
            <v-row class="mobileStatistics">
                <v-col cols="12" class="mobileCol">
                    <p class="mobileTitle">6000+</p>
                </v-col>
                <v-col cols="12" class="mobileCol">
                    <p class="mobileSubtitle">Words</p>
                </v-col>
                <v-col cols="12" class="mobileCol">
                    <p class="mobileTitle">22</p>
                </v-col>
                <v-col cols="12" class="mobileCol">
                    <p class="mobileSubtitle">Word Themes</p>
                </v-col>
                <v-col cols="12" class="mobileCol">
                    <p class="mobileTitle">17</p>
                </v-col>
                <v-col cols="12" class="mobileCol">
                    <p class="mobileSubtitle">Word Languages</p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
  name: "Content",

  data: () => ({
      isMobile: false,
  }),

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 801;
    },
  }
};
</script>

<style scoped>
.statistics
{
    padding-left: 28vw;
    padding-right: 28vw;
    padding-top: 5.5vh;
    padding-bottom: 2vh;
}

.mobileStatistics
{
    padding-top: 5.5vh;
    padding-bottom: 2vh;
}

.mobileCol
{
    padding: 0 !important;
}

.title
{
    color: #2f2a1b;
    font-size: 3rem !important;
    font-weight: 600;
}

.mobileTitle
{
    color: #2f2a1b;
    font-size: 2.6rem !important;
    font-weight: 600;
    margin-bottom: 0 !important;
}

.subtitle
{
    color: #9a8248;
    font-size: 1.4rem !important;
    font-weight: normal;
}

.mobileSubtitle
{
    color: #9a8248;
    font-size: 1.6rem !important;
    font-weight: normal;
    margin-bottom: 5vh;
}
</style>