<template>
  <div>
    <v-container class="background light" v-if="!isMobile">
        <v-row class="text-center" justify="center">
            <v-col cols="12">
                <p class="title">Play on Discord<br>
                with <a class="interactionText">your friends.</a></p>

                <p class="subtitle">Endless words, 22 themes and<br>
                a server-wide leaderboard</p>

                <v-btn depressed dark class="interaction landing button" @click="addDiscord">Add to Server</v-btn>
            </v-col>

            <v-col cols="6" class="gameplay">
                <v-img
                    alt="Gameplay Screenshot 1"
                    :src="require('../assets/Gameplay_1.png')"
                    class="shrink mr-2 icon"
                    contain
                    position="right"
                    height="71.5vh">
                </v-img>
            </v-col>
            <v-col cols="6" class="gameplay">
                <v-img
                    alt="Gameplay Screenshot 2"
                    :src="require('../assets/Gameplay_2.png')"
                    class="shrink mr-2 icon"
                    contain
                    position="left"
                    height="71.5vh">
                </v-img>
            </v-col>
        </v-row>
    </v-container>

    <v-container class="background light" v-if="isMobile">
        <v-row class="text-center" justify="center">
            <v-col cols="12">
                <p class="title">Play on<br>
                Discord<br>
                with <a class="interactionText">your<br>
                friends.</a></p>

                <p class="mobileSubtitle">Endless words, 22 themes and<br>
                a server-wide leaderboard</p>

                <v-btn depressed dark class="interaction landing button mb-4" @click="addDiscord">Add to Server</v-btn>

                <v-img
                  alt="Gameplay Screenshot 1"
                  :src="require('../assets/Gameplay_1.png')"
                  class="imageMobile"
                  contain
                  width="90vw">
                </v-img>

                <v-img
                  alt="Gameplay Screenshot 2"
                  :src="require('../assets/Gameplay_2.png')"
                  class="imageMobile"
                  contain
                  width="90vw">
                </v-img>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Landing",

  data: () => ({
    isMobile: false,
  }),

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 801;
    },

    addDiscord()
    {
        window.open("https://discord.com/login?redirect_to=%2Foauth2%2Fauthorize%3Fclient_id%3D1076499064390156348%26permissions%3D52288%26scope%3Dapplications.commands%2520bot", '_blank').focus();
    }
  },
};
</script>

<style scoped>
.title
{
  color: #2F2A1B !important;
  padding-top: 6vh;
  font-size: 4.75rem !important;
  font-weight: bolder;
  line-height: 5rem !important;
}

.interactionText
{
    color: #1E90DF;
}

.interaction.landing.button
{
    padding: 20px !important;
}

.subtitle
{
  padding-top: 0.5vh;
  font-size: 1.6rem !important;
  font-weight: normal;
  color: #6E5F39;
}

.mobileSubtitle
{
  padding-top: 0.5vh;
  font-size: 1.5rem !important;
  font-weight: normal;
  color: #6E5F39;
}

.gameplay
{
    padding: 5.5vh 2vw 10vh;
}

.imageMobile
{
  margin-left: 2vw;
  padding-bottom: 10vh;
}
</style>