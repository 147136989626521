<template>
    <div class="background dark header">
        <v-img
                alt="Guess the Word Logo"
                :src="require('../assets/SimonIcon.svg')"
                class="shrink"
                contain
        />

        <v-spacer></v-spacer>

        <v-btn text class="header button normal mr-2" @click="$router.push({ name: 'Home', hash: '#how-to-play' })" v-if="!isMobile">How To Play</v-btn>
        <v-btn depressed dark class="interaction header button" @click="addDiscord" v-if="!isMobile">Add to Server</v-btn>
    </div>
</template>

<script>
export default {
  name: "Header",

  data: () => ({
    isMobile: false,
    drawer: false,
    group: null,
  }),

  watch: {
    group () {
      this.drawer = false
    },
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 801;
    },

    addDiscord()
    {
        window.open("https://discord.com/login?redirect_to=%2Foauth2%2Fauthorize%3Fclient_id%3D1076499064390156348%26permissions%3D52288%26scope%3Dapplications.commands%2520bot", '_blank').focus();
    }
  },
};
</script>

<style>
.background.header
{
    display: flex;
    padding: 20px;
}

.header.button
{
    padding: 13px !important;
}

.header.button.normal
{
    color: white;
}

.interaction.header.button
{
    border-radius: 8px !important;
    font-size: 0.75rem !important; 
}
</style>